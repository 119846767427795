import React from "react"
import PropTypes from "prop-types"
import loadable from "@loadable/component"
import { useOverlayStore } from "../../stores/overlay"

const Button = loadable(() => import("../Button"))

const BookingEnquiryRetreat = ({ register, errors }) => {
  const language = process.env.GATSBY_SITE_LANGUAGE
  const { title, rate } = useOverlayStore()

  return (
    <>
      <input
        type="hidden"
        name="subject"
        data-remove-prefix
        value={"Soul Valley Retreat Booking Enquiry"}
        {...register("subject")}
      />
      <input
        type="hidden"
        name="booking-rate"
        value={rate || ""}
        {...register("booking-rate")}
      />
      <input
        type="hidden"
        name="retreat"
        value={title || null}
        {...register("retreat")}
      />
      <input
        type="hidden"
        name="selected-retreat-url"
        value={typeof window !== "undefined" ? window.location.href : ""}
        {...register("selected-retreat-url")}
      />
      <div className="formField">
        <label htmlFor="name">
          <input
            type="text"
            name="name"
            id="name"
            placeholder=" "
            className={errors?.name && "is-invalid"}
            aria-invalid={errors?.name ? "true" : "false"}
            {...register("name", { required: true })}
          />
          <span>
            {language == "en" ? (
              <>
                Your name <span>(required)</span>
              </>
            ) : (
              <>
                Il tuo nome <span>(necessario)</span>
              </>
            )}
          </span>
        </label>
      </div>
      <div className="formField">
        <label htmlFor="number-of-guests">
          <input
            type="text"
            name="number-of-guests"
            id="number-of-guests"
            placeholder=" "
            className={errors?.name && "is-invalid"}
            aria-invalid={errors?.name ? "true" : "false"}
            {...register("number-of-guests", { required: true })}
          />
          <span>
            {language == "en" ? (
              <>
                Number of Guests <span>(required)</span>
              </>
            ) : (
              <>
                Numero di Ospiti <span>(necessario)</span>
              </>
            )}
          </span>
        </label>
      </div>
      <div className="formField">
        <label htmlFor="email">
          <input
            type="text"
            name="email"
            id="email"
            placeholder=" "
            className={errors?.email && "is-invalid"}
            aria-invalid={errors?.email ? "true" : "false"}
            {...register("email", {
              required: true,
              pattern:
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            })}
          />
          <span>
            {language == "en" ? (
              <>
                Your e-mail <span>(required)</span>
              </>
            ) : (
              <>
                La tua email <span>(necessario)</span>
              </>
            )}
          </span>
        </label>
      </div>
      <div className="formField">
        <label htmlFor="phone">
          <input
            type="tel"
            name="phone"
            id="phone"
            placeholder=" "
            {...register("phone")}
          />
          <span>
            {language == "en" ? (
              <>
                Your phone <span>(optional)</span>
              </>
            ) : (
              <>
                Il tuo telefono <span>(facoltativo)</span>
              </>
            )}
          </span>
        </label>
      </div>
      <div className="pl-3 mb-4">
        <p className="text-sm leading-tight text-gray-800">
          {language === "en"
            ? "Preferred contact method"
            : "Metodo di contatto preferito"}
        </p>
        <div className="flex items-center mt-2">
          <input
            {...register("preferred-contact-method")}
            type="radio"
            name="preferred-contact-method"
            value="Phone"
            className="mr-2"
            checked
          />{" "}
          <p className="mt-1 mr-4 text-sm leading-tight text-gray-800">
            {language === "en" ? "Phone" : "Telefono"}
          </p>
          <input
            {...register("preferred-contact-method")}
            type="radio"
            name="preferred-contact-method"
            value="Email"
            className="mr-2"
          />{" "}
          <p className="mt-1 text-sm leading-tight text-gray-800">
            {language === "en" ? "Email" : "E-mail"}
          </p>
        </div>
      </div>
      <div className="formField formTextarea">
        <label htmlFor="message">
          <textarea
            name="message"
            id="message"
            placeholder=" "
            className={errors?.message && "is-invalid"}
            aria-invalid={errors?.message ? "true" : "false"}
            {...register("message", { required: true })}
          />
          <span>
            {language == "en" ? (
              <>
                Questions or remarks <span>(required)</span>
              </>
            ) : (
              <>
                Domande o osservazioni <span>(necessario)</span>
              </>
            )}
          </span>
        </label>
      </div>
      <div className="mt-4 text-center">
        <Button type="submit" colour="green" full>
          {language == "en" ? <>Send</> : <>Invia</>}
        </Button>

        {errors?.name || errors?.email || errors?.message ? (
          <p className="mt-2 text-sm text-red-500" role="alert">
            {errors.email?.type === "pattern" ? (
              <>
                {language == "en" ? (
                  <>Please enter a valid email address.</>
                ) : (
                  <>Si prega di inserire un indirizzo email valido.</>
                )}
              </>
            ) : (
              <>
                {language == "en" ? (
                  <>Please answer all required fields.</>
                ) : (
                  <>Si prega di rispondere a tutti i campi obbligatori.</>
                )}
              </>
            )}
          </p>
        ) : (
          <p className="mt-2 text-sm">
            {language == "en" ? (
              <>Thanks for your request. No obligations.</>
            ) : (
              <>Rispondiamo entro 24 ore. Nessun obbligo.</>
            )}
          </p>
        )}
      </div>
    </>
  )
}

export default BookingEnquiryRetreat

BookingEnquiryRetreat.propTypes = {
  register: PropTypes.func,
  errors: PropTypes.object,
}
